import { onLoad } from '~/lib/on-load'

export default (context, inject) => {
  const showAdaChat = context.$staticDataService.cmsLayoutsDefault.app?.showAdaChat

  if (!showAdaChat) {
    return
  }

  onLoad(() => initializeAda(context), 5000)

  /**
   * @alias this.$adaChatBot
   */
  inject('adaChatBot', {
    async openChat() {
      const currentState = await window.adaEmbed.getInfo()

      if (!currentState.isDrawerOpen) {
        window.adaEmbed.toggle()
      }
    },
    async closeChat() {
      const currentState = await window.adaEmbed.getInfo()

      if (currentState.isDrawerOpen) {
        window.adaEmbed.toggle()
      }
    },
    initializeAda() {
      initializeAda(context)
    },
  })
}

function initializeAda(context) {
  if (window.adaLoaded) return

  loadAdaSettings(context)

  const script = document.createElement('script')
  script.src = 'https://static.ada.support/embed2.js'
  script.id = '__ada'
  script.setAttribute('data-handle', 'loavies')

  document.body.appendChild(script)
}

function loadAdaSettings(context) {
  window.adaLoaded = false

  window.adaSettings = {
    adaReadyCallback: () => {
      context.store.dispatch('chat/setLoaded', { loaded: true })
      context.store.dispatch('chat/setChatIsOnline', { chatIsOnline: true })

      window.adaLoaded = true
    },
  }
}
