import getPrimarySkuFromProduct from '~/lib/get-primary-sku-from-product'
import getPriceExcludingTax from '~/lib/get-price-excluding-tax'
import getPrimaryCategoryFromProduct from '~/lib/get-primary-category-from-product'
import getPriceGroupFromStorefront from '~/lib/get-price-group-from-storefront'
import getTaxFromPrice from '~/lib/get-tax-from-price'
import cookie from '~/lib/cookie'
import { SOCIAL_MEDIA_IDS_COOKIE_NAME } from '~/lib/constants'

/**
 * @typedef {TrackingService}
 * @alias this.$trackingService
 */
export class TrackingService {
  #taxRate
  #eecNormalProduct
  #eecSaleProduct
  #socialMediaIds

  constructor(context) {
    /** @type {NuxtContext} */
    this.context = context
    this.store = context.store

    this.#taxRate = .21
    this.#eecNormalProduct = 'Normal'
    this.#eecSaleProduct = 'Sale'
    this.#socialMediaIds = ['sccid', 'fbclid', 'epik', 'gclid', 'ttclid']
  }

  init() {
    this.$gtmService = this.context.$gtmService
    this.$bloomreachService = this.context.$bloomreachService
    this.$productApiService = this.context.$productApiService
    this.$bambuserTrackingService = this.context.$bambuserTrackingService
    this.$countryService = this.context.$countryService
    this.$grinService = this.context.$grinService
  }

  /**
   * @return {Object}
   */
  getSocialsMediaIdsFromCookie() {
    let storedSocialIds

    try {
      storedSocialIds = JSON.parse(cookie(SOCIAL_MEDIA_IDS_COOKIE_NAME))
    } catch {
      storedSocialIds = {}
    }

    return storedSocialIds
  }

  /**
   * @param {Object} query
   */
  storeSocialMediaIdsFromQuery(query) {
    const mappedValues = Object
        .entries(query)
        .reduce((acc, [key, value]) => {
          if (this.#socialMediaIds.includes(key)) {
            acc[key] = value
          }

          return acc
        }, {})

    this.storeSocialMediaIdsToCookie(mappedValues)
  }

  /**
   * @param {Object} payload
   */
  storeSocialMediaIdsToCookie(payload) {
    const currentSocialMediaIds = this.getSocialsMediaIdsFromCookie()

    cookie(SOCIAL_MEDIA_IDS_COOKIE_NAME, Object.assign(currentSocialMediaIds, payload), { expires: 1 / 24 }) // one hour
  }

  /**
   * @param {string} eventAction
   * @param {string} eventLabel
   */
  registerSplitTest({ eventAction, eventLabel }) {
    this.$gtmService.add({
      event: 'ga-event', // Do not change
      eventCategory: 'ab-test', // Do not change
      eventAction,
      eventLabel,
      eventValue: 0,
    })
  }

  /**
   * @param {string} email
   */
  identifyUser(email) {
    this.$bloomreachService.identifyUser(email)
  }

  registerUser() {
    this.$gtmService.add({
      event: 'Accountregistratie',
      eventCategory: window.location.href,
      eventAction: 'Subscription',
      at: this.getSocialsMediaIdsFromCookie(),
    })
  }

  /**
   * @param {Object} product
   */
  visitProductDetailPage(product) {
    const primarySku = getPrimarySkuFromProduct(product)
    const price = this.#getProductPrice(product)
    const originalPrice = this.#getProductPrice(product, true)
    const primaryCategory = getPrimaryCategoryFromProduct(product)

    this.$gtmService.add({
      event: 'eec.detail',
      ecommerce: {
        detail: {
          products: [{
            name: product.name?.[0],
            id: primarySku,
            price,
            priceexcludingtax: getPriceExcludingTax(price, this.#taxRate),
            tax: getTaxFromPrice(price, this.#taxRate),
            taxrate: this.#taxRate * 100,
            category: product.option_text_model?.[0],
            brand: product.option_text_brand?.[0],
          }],
          at: this.getSocialsMediaIdsFromCookie(),
        },
      },
    })

    this.$gtmService.add({
      event: 'fireRemarketingTag',
      google_tag_params: {
        ecomm_prodid: primarySku,
        ecomm_pagetype: 'product',
        ecomm_totalvalue: price,
      },
    })

    this.$bloomreachService.viewProduct({
      product_id: product.entity_id,
      title: product.name?.[0],
      category_level_1: primaryCategory?.name,
      price,
      original_price: originalPrice,
      location: window.location.href,
      product_sku: primarySku,
    })
  }

  /**
   * @param {Object} category
   */
  visitCategoryPage(category) {
    this.$bloomreachService.viewCategory({
      category_name: category.name[0],
      category_level_1: category.name[0],
      categories_path: category.url_path[0],
      location: window.location.href,
      domain: window.location.origin,
      language: this.store.state.i18n.locale,
    })
  }

  /**
   * @param {Object} product
   * @param {string} [location]
   */
  productClick({ product, location = '' }) {
    const price = this.#getProductPrice(product)

    this.$gtmService.add({
      event: 'EEproductClick',
      ecommerce: {
        click: {
          actionField: {
            list: location,
          },
          products: [{
            name: product.name?.[0],
            id: getPrimarySkuFromProduct(product),
            price,
            priceexcludingtax: getPriceExcludingTax(price, this.#taxRate),
            taxrate: this.#taxRate * 100,
            brand: product.option_text_brand?.[0],
            category: product.option_text_model?.[0],
          }],
        },
      },
    })
  }

  /**
   * @param {Object} product
   * @param {number} quantity
   * @param {Object} [selectedSize]
   * @param {string} [origin]
   */
  addToCart({ product, quantity, selectedSize, origin = 'product' }) {
    const primarySku = getPrimarySkuFromProduct(product)
    const originalPrice = this.#getProductPrice(product, true)
    const price = this.#getProductPrice(product)
    const primaryCategory = getPrimaryCategoryFromProduct(product)

    this.$gtmService.add({
      event: 'EEaddToCart',
      ecommerce: {
        currencyCode: this.store.state.localization.currency,
        add: {
          at: this.getSocialsMediaIdsFromCookie(),
          products: [{
            id: primarySku,
            name: product.name?.[0],
            price,
            totalCartItemsValueExclTax: this.store.state.checkout.cartSubTotalExclTax,
            brand: product.option_text_brand?.[0],
            category: product.option_text_model?.[0],
            variant: selectedSize?.store_label ?? null,
            quantity,
            dimension7: product.special_price?.length ? this.#eecSaleProduct : this.#eecNormalProduct,
          }],
        },
      },
    })

    this.$bloomreachService.cartUpdate({
      action: 'add',
      eventPayload: {
        page_type: origin,
        product_id: product.entity_id,
        title: product.name?.[0],
        product_group: primaryCategory?.name,
        price,
        original_price: originalPrice,
        location: window.location.href,
        domain: window.location.origin,
        language: this.store.state.i18n.locale,
        product_list: this.store.getters['checkout/getProductListFromCartItems'],
        product_ids: this.store.getters['checkout/getEntityIdsFromCartItems'],
        product_skus: this.store.getters['checkout/getPrimarySkusFromCartItems'],
        skus: this.store.getters['checkout/getSizeSkusFromCartItems'],
        quantity,
        total_quantity: this.store.state.checkout.cartItems.length,
        total_price: this.store.state.checkout.cartSubTotal,
        total_price_without_tax: this.store.state.checkout.cartSubTotalExclTax,
        product_sku: primarySku,
        sku: selectedSize?.product_sku ?? null,
      },
    })
  }

  /**
   * @param {Object} item
   * @param {number} quantity
   * @param {boolean} [itemsMovedToWishlist]
   */
  removeFromCart({ cartItem, quantity, itemsMovedToWishlist = false }) {
    const product = cartItem.product

    if (!product) {
      return
    }

    const primarySku = getPrimarySkuFromProduct(product)
    const price = this.#getProductPrice(product)
    const originalPrice = this.#getProductPrice(product, true)
    const primaryCategory = getPrimaryCategoryFromProduct(product)

    this.$gtmService.add({
      event: 'EEremoveFromCart',
      ecommerce: {
        remove: {
          products: [{
            id: primarySku,
            name: product.name?.[0],
            price,
            totalCartItemsValueExclTax: this.store.state.checkout.cartSubTotalExclTax,
            brand: product.option_text_brand?.[0],
            category: product.option_text_model?.[0],
            variant: cartItem.sku,
            quantity,
          }],
        },
      },
    })

    this.$bloomreachService.cartUpdate({
      action: itemsMovedToWishlist ? 'to wishlist' : 'remove',
      eventPayload: {
        product_id: product.entity_id,
        title: product.name?.[0],
        product_group: primaryCategory?.name,
        price,
        original_price: originalPrice,
        location: window.location.href,
        domain: window.location.origin,
        language: this.store.state.i18n.locale,
        product_list: this.store.getters['checkout/getProductListFromCartItems'],
        product_ids: this.store.getters['checkout/getEntityIdsFromCartItems'],
        product_skus: this.store.getters['checkout/getPrimarySkusFromCartItems'],
        skus: this.store.getters['checkout/getSizeSkusFromCartItems'],
        quantity,
        total_quantity: this.store.state.checkout.cartItems.length,
        total_price: this.store.state.checkout.cartSubTotal,
        total_price_without_tax: this.store.state.checkout.cartSubTotalExclTax,
        product_sku: primarySku,
        sku: cartItem.sku,
      },
    })
  }

  /**
   * @param {Object[]} cartItems
   */
  visitCheckoutPage(cartItems) {
    const products = cartItems.map(item => {
      return {
        name: item.name,
        id: item.product ? getPrimarySkuFromProduct(item.product) : '', // Top level SKU
        price: item.extension_attributes?.price_incl_tax,
        variant: item.sku, // Size SKU
        category: item.product?.option_text_model?.[0],
        brand: item.product?.option_text_brand?.[0],
        quantity: item.qty,
      }
    })

    this.$gtmService.add({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: {
            step: 1,
          },
          products,
          at: this.getSocialsMediaIdsFromCookie(),
        },
      },
    })

    this.$bloomreachService.visitCheckout({
      channel: 'Web',
      page_type: origin,
      location: window.location.href,
      domain: window.location.origin,
      language: this.store.state.i18n.locale,
      product_list: this.store.getters['checkout/getProductListFromCartItems'],
      product_ids: this.store.getters['checkout/getEntityIdsFromCartItems'],
      product_skus: this.store.getters['checkout/getPrimarySkusFromCartItems'],
      skus: this.store.getters['checkout/getSizeSkusFromCartItems'],
      total_quantity: this.store.state.checkout.cartItems.length,
      total_price: this.store.state.checkout.cartSubTotal,
      total_price_without_tax: this.store.state.checkout.cartSubTotalExclTax,
    })
  }

  /**
   * @param {PaymentMethod} [paymentMethod]
   */
  selectPaymentMethod(paymentMethod) {
    this.$gtmService.add({
      event: 'checkoutOption',
      ecommerce: {
        checkout_option: {
          actionField: {
            step: 2,
            option: paymentMethod.id,
          },
        },
      },
    })
  }

  /**
   * @param {Object} order
   * @param {string} orderId
   * @param {Object[]} orderItems
   * @param {Object} orderAddress
   * @returns {Promise<void>}
   */
  async purchase({ order, orderId, orderItems, orderAddress }) {
    try {
      const skus = orderItems.map(item => item.sku)
      const elasticProducts = await this.$productApiService.getMultipleProductsBySku(skus)
      const products = orderItems.map(orderItem => {
        const product = elasticProducts.find(product => {
          if (Array.isArray(product.sku)) {
            return product.sku.includes(orderItem.sku)
          }

          return product.sku === orderItem.sku
        })

        return {
          name: orderItem.name,
          id: orderItem.extension_attributes?.parent_sku,
          price: orderItem.price_incl_tax,
          variant: orderItem.sku, // Size SKU
          category: product?.option_text_model?.[0],
          brand: product?.option_text_brand?.[0],
          quantity: orderItem.qty_ordered,
          dimension7: orderItem.price_incl_tax < orderItem.original_price ? this.#eecSaleProduct : this.#eecNormalProduct,
          metric1: orderItem.original_price - orderItem.price_incl_tax,
        }
      })

      this.$gtmService.add({
        event: 'purchase',
        ecommerce: {
          purchase: {
            actionField: {
              id: orderId,
              affiliation: order.extension_attributes?.shipping_assignments?.[0].shipping?.address?.country_id, //shipping country
              coupon: order.coupon_code,
              currency: order.base_currency_code,
              shipping: order.base_shipping_incl_tax,
              revenue: order.base_grand_total, //grand total incl tax
              tax: order.base_tax_amount,
            },
            at: this.getSocialsMediaIdsFromCookie(),
            products,
            email: order.customer_email,
            first_name: order.customer_firstname,
            last_name: order.customer_lastname,
            street: orderAddress.street.join(' '),
            city: orderAddress.city,
            postal_code: orderAddress.postcode,
            country: this.$countryService.getShippingCountryByCountryId(orderAddress.countryId)?.full_name_locale || orderAddress.countryId,
          },
        },
      })

      this.$bambuserTrackingService.purchase({
        orderId,
        orderValue: order.base_grand_total,
        orderProductIds: products.map(product => product.id).join(','),
        currency: order.base_currency_code,
      })

      this.$grinService.reportPurchase({
        orderId,
        currency: order.base_currency_code,
        subTotalExclTax: order.base_subtotal,
      })
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error('Error during purchase tracking', error)
      }
    }
  }

  /**
   * @param {string} email
   * @param {string} [newsletterSubscriptionTag]
   */
  subscribeToNewsletter({ email, newsletterSubscriptionTag = 'default-newsletter' }) {
    this.$gtmService.add({
      event: 'newsletter-subscription',
      eventCategory: window.location.href,
      eventAction: 'newsletter-subscription',
      eventValue: newsletterSubscriptionTag,
      at: this.getSocialsMediaIdsFromCookie(),
    })

    this.identifyUser(email)

    this.$bloomreachService.newsletterConsent(newsletterSubscriptionTag, email)
  }

  /**
   * @param {string} email
   * @param {string} sku
   * @param {string} [phone]
   */
  subscribeToOutOfStock({ email, sku, phone }) {
    this.$bloomreachService.trackProductStock({ email, sku, phone })
  }

  /**
   * @param {string} searchValue
   * @param {Object[]} categories
   */
  search({ searchValue, categories }) {
    this.$bloomreachService.search({
      searchValue,
      categories: categories
        .slice(0, 3)
        .map(category => {
          return {
            name: category.name,
          }
        }),
    })
  }

  /**
   * @param {'add'|'remove'} action
   * @param {Object} product
   */
  handleWishlistAction({ action, product }) {
    const sku = getPrimarySkuFromProduct(product)
    const price = this.#getProductPrice(product)
    const originalPrice = this.#getProductPrice(product, true)
    const primaryCategory = getPrimaryCategoryFromProduct(product)

    this.$bloomreachService.wishlistUpdate({
      action,
      eventPayload: {
        product_id: product.entity_id,
        title: product.name?.[0],
        product_group: primaryCategory?.name,
        price,
        original_price: originalPrice,
        location: window.location.href,
        domain: window.location.origin,
        language: this.store.state.i18n.locale,
        product_list: this.store.getters['wishlist/productListFromWishlistItems'],
        product_ids: this.store.getters['wishlist/productEntityIdsFromWishlistItems'],
        total_price: this.store.getters['wishlist/totalPriceOfWishlistInclTax'],
        total_price_without_tax: this.store.getters['wishlist/totalPriceOfWishlistExclTax'],
        product_sku: sku,
        product_skus: this.store.getters['wishlist/primarySkusFromWishlistItems'],
      },
    })
  }

  updateCategoryProductFilters() {
    const filtered = Object.keys(this.store.state.category.checkedFilters ?? {})
    const sortBy = this.store.state.category.categoryOptions?.sortBy

    this.$bloomreachService.updateFilters({
      filtered,
      sort: sortBy?.value,
      sort_order: sortBy?.sort_order,
    })
  }

  updateSearchProductFilters() {
    const filtered = Object.keys(this.store.state.search.checkedFilters ?? {})
    const sortBy = this.store.state.search.activeSortOption

    this.$bloomreachService.updateFilters({
      type: 'search_filter',
      filtered,
      sort: sortBy?.value,
      sort_order: sortBy?.sort_order,
    })
  }

  /**
   * @param {Object} product
   * @param {boolean} [originalPrice]
   * @returns {number}
   */
  #getProductPrice(product, originalPrice = false) {
    const customerGroupId = this.store.state.user.customerGroupId
    const currency = this.store.state.localization.currency
    const priceGroup = product.prices_storefront
      ? getPriceGroupFromStorefront(product.prices_storefront, customerGroupId, currency)
      : null
    const price = originalPrice
      ? priceGroup?.original_price ?? null
      : priceGroup?.price ?? null

    return Number(Number(price).toFixed(2))
  }
}
