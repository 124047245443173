export default async (context) => {
  const {
    search_query: optimizers,
    query_fields: boosters,
  } = await context.$magentoApiService
    .get('/optimizer/search')
    .then(response => response.data?.[0])
    .catch(() => {
      return {}
    })

  await context.store.dispatch('search/addOptimizers', optimizers?.function_score)
  await context.store.dispatch('search/addDefaultBoosters', boosters?.default)
  await context.store.dispatch('search/addSynonymBoosters', boosters?.synonym)
}
